<template>
  <div id="app" class="">
    <div class="header">
      <h1 class="text-center mb-4">Chat with GPT-4</h1>
      <button class="clear-history-button" @click="clearHistory">Clear History</button>
    </div>

    <div class="main container">
      <div v-if="history.length" class="card">
        <div class="card-body" ref="messageContainer">
          <div v-for="(msg, index) in history" :key="index" class="mb-2 flex">
            <strong v-if="msg.role === 'user'" class=""></strong>
            <div :class="{'items-end': msg.role === 'user', 'items-start': msg.role !== 'user'}">
              <div :class="{'user-mesage': msg.role === 'user'}">
                <template v-if="msg.role !== 'user'">
                  <ChatGptIcon class="gpt-logo"/>
                </template>
                <span v-html="parseMarkdown(msg.content)"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="flex w-full items-center">
        <div class="flex items-end gap-1.5 md:gap-2">
          <div class="flex w-full flex-col gap-1.5 rounded-[26px] p-1.5 transition-colors bg-[#f4f4f4] dark:bg-token-main-surface-secondary">
            <div class="flex min-w-0 flex-1 flex-col w-full">
              <textarea v-model="message" type="text" class="form-control w-full text-ar" wrap="hard" placeholder="Сообщить ChatGPT"
                        @keydown="handleKeyDown" @input="autoResize" ref="textarea"/>
            </div>
          </div>
        </div>
      </div>
      <div class="m-3 pl-5">
        <button class="rounded-5" @click="sendMessage" style="margin-right: .25rem;">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M10.854 8.354a.5.5 0 0 0 0-.708l-3.792-3.792a.5.5 0 1 0-.708.708L9.293 7.5H1.5a.5.5 0 0 0 0 1h7.793l-3.646 3.646a.5.5 0 0 0 .708.708l3.792-3.792a.5.5 0 0 0 0-.708z"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MarkdownIt from 'markdown-it';
import ChatGptIcon from '../src/components/ChatGptIcon.vue';

export default {
  components: {
    ChatGptIcon,
  },
  data() {
    return {
      message: '',
      response: null,
      error: null,
      history: [],
    };
  },
  created() {
    this.loadChatHistory();
  },
  updated() {
    this.scrollToBottom();
  },
  methods: {
    async sendMessage() {
      if (this.message.trim() === '') return;

      const userMessage = {role: 'user', content: this.message};
      this.history.push(userMessage);

      try {
        const res = await axios.post('http://10.32.8.197:5000/chat', {message: this.message});
        const botMessage = {role: 'assistant', content: res.data};
        this.history.push(botMessage);
        this.message = '';
        this.error = null;
      } catch (error) {
        this.handleError(error);
      }
      this.saveChatHistory();
    },
    handleKeyDown(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        this.sendMessage();
      }
    },
    autoResize() {
      const textarea = this.$refs.textarea;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    },
    async uploadFile(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      try {
        const res = await axios.post('http://10.32.8.197:5000/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(res.data);
        this.error = null;
      } catch (error) {
        this.handleError(error);
      }
    },
    parseMarkdown(content) {
      const md = new MarkdownIt();
      return md.render(content);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    loadChatHistory() {
      const savedHistory = localStorage.getItem('chat_history');
      if (savedHistory) {
        this.history = JSON.parse(savedHistory);
      }
    },
    clearHistory() {
      this.history = [];
      localStorage.removeItem('chat_history');
    },
    saveChatHistory() {
      localStorage.setItem('chat_history', JSON.stringify(this.history));
    },
    handleError(error) {
      if (error.response) {
        if (error.response.status === 401) {
          this.error = 'Invalid API key or authentication error. Please check your credentials.';
        } else if (error.response.status === 429) {
          this.error = 'Rate limit reached. Please wait and try again later.';
        } else {
          this.error = `Error: ${error.response.data.error.message}`;
        }
      } else if (error.request) {
        this.error = 'Network Error. Please check your network connection and try again.';
      } else {
        this.error = 'Error sending request. Please try again later.';
      }
      console.error(error);
    }
  }
};
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #f1f1f1;
  padding: 10px;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.main {
  flex: 1;
  padding: 60px 10px 80px;
  overflow-y: auto;
  margin-top: 50px; /* Высота шапки */
}

.footer {
  background-color: #f1f1f1;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 10px;
}

.items-start {
  justify-content: flex-start;
  height: auto;
}

.items-end {
  justify-content: flex-end;
  height: auto;
}

.text-ar {
  margin: auto;
  margin-left: 100px;
  width: 100%;
  min-width: 600px;
}

.gpt-logo {
  margin: 0px;
  margin-right: 10px;
}

.user-mesage {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: .625rem;
  border-radius: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgba(244, 244, 244, var(--tw-bg-opacity));
}
</style>
